<style scoped>
    .regra-container {
        display: flex;
        position: relative;
        margin-bottom: 16px;
    }
    .regra {
        margin-right: 16px;
    }
</style>

<template>
    <div class="regra-container">
        <div class="regra" v-if="inputShouldBeVisible('qtdeMin')">
            <div class="form-group">
                <label>Qtde Mín</label>
                <input 
                    class="form-control" 
                    v-model="item.qtdeMin" 
                    maxlength="30" 
                    type="number"
                    :disabled="item.erpId && item.id" 
                />
                <div class="message">{{ validacao.firstError("item.qtdeMin") }}</div>
            </div>
        </div>
        <div class="regra" v-if="inputShouldBeVisible('qtdeMax')">
            <div class="form-group">
                <label>Qtde Máx</label>
                <input 
                    class="form-control" 
                    v-model="item.qtdeMax" 
                    maxlength="30" 
                    type="number"
                    :disabled="item.erpId && item.id" 
                />
                <div class="message">{{ validacao.firstError("item.qtdeMax") }}</div>
            </div>
        </div>
        <div class="regra" v-if="inputShouldBeVisible('qtdeMinDistinta')">
            <div class="form-group">
                <label>Qtde Dist Mín</label>
                <input 
                    class="form-control" 
                    v-model="item.qtdeMinDistinta" 
                    maxlength="30" 
                    type="number"
                    :disabled="item.erpId && item.id" 
                />
                <div class="message">{{ validacao.firstError("item.qtdeMinDistinta") }}</div>
            </div>
        </div>
        <div class="regra" v-if="inputShouldBeVisible('qtdeMaxDistinta')">
            <div class="form-group">
                <label>Qtde Dist Máx</label>
                <input class="form-control" v-model="item.qtdeMaxDistinta" maxlength="30" type="number"
                    :disabled="item.erpId && item.id" />
                <div class="message">{{ validacao.firstError("item.qtdeMaxDistinta") }}</div>
            </div>
        </div>
        <div class="regra" v-if="inputShouldBeVisible('vlMin')">
            <div class="form-group">
                <label>Valor Mínimo</label>
                <money class="form-control" v-model="item.vlMin" v-bind="monetary" :disabled="item.erpId && item.id" />
                <div class="message">{{ validacao.firstError("item.vlMin") }}</div>
            </div>
        </div>
        <div class="regra" v-if="inputShouldBeVisible('vlMax')">
            <div class="form-group">
                <label>Valor Máximo</label>
                <money class="form-control" v-model="item.vlMax" v-bind="monetary" :disabled="item.erpId && item.id" />
                <div class="message">{{ validacao.firstError("item.vlMax") }}</div>
            </div>
        </div>
        <div class="regra" v-if="inputShouldBeVisible('percMin')">
            <div class="form-group">
                <label>Percentual Mínimo</label>
                <input-percent class="form-control" v-model="item.percMin" :disabled="item.erpId && item.id" />
                <div class="message">{{ validacao.firstError("item.percMin") }}</div>
            </div>
        </div>
        <div class="regra" v-if="inputShouldBeVisible('percMax')">
            <div class="form-group">
                <label>Percentual Máximo</label>
                <input-percent class="form-control" v-model="item.percMax" :disabled="item.erpId && item.id" />
                <div class="message">{{ validacao.firstError("item.percMax") }}</div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'tipoValidacao',
    props: [
      'item',
      'inputShouldBeVisible',
      'validacao',
    ],
    data() {
      return {
        monetary: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          masked: false,
        },
      }
    },
    watch: {        
        'item.percMin'() {
            this.validaPercNegativo();
        },
        'item.percMax'() {
            this.validaPercNegativo();
        },
    },
    methods : {
        validaPercNegativo(){  
            
            if(this.item.percMin < 0){
               this.item.percMin = this.changeToPositive(this.item.percMin);
            }

            if(this.item.percMax < 0){
               this.item.percMax = this.changeToPositive(this.item.percMax);
            }
        },
        changeToPositive(value){
            return value * -1;
        }
    }
  };
</script>
