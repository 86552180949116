<template>
  <div class="col-1-1">
    <div class="regra-produto-card">
      <h4>Regra de validação</h4>

      <div class="regra-produto-container">
        <DropdownInput
            :value="dimensaoCampanha.idProd"
            label="PRODUTO"
            field="idProd"
            :index="index"
            @input="updateField(index, 'idProd', $event, dimensaoCampanha, 'idProdAplicacao')"
        />

        <div class="regra-produto">
          <DropdownInput
              :value="dimensaoCampanha.idProdPrincipal"
              label="PRODUTO PRINCIPAL"
              field="idProdPrincipal"
              :index="index"
              @input="updateField(index, 'idProdPrincipal', $event, dimensaoCampanha, 'idProdPrincipalAplicacao')"
          />
        </div>
      </div>

      <h4>
        Regra de aplicação |
        <small>
          <label>
            <input
                type="checkbox"
                v-model="dimensaoCampanha.aplicaregra"
                :value="true"
                v-on:change="clonarRegra(dimensaoCampanha)"
                :disabled="campanha.erpId && campanha.id"
            />
            Aplicar o desconto nos mesmos itens da regra de validação
          </label>
        </small>
      </h4>

      <div class="regra-produto-container">
        <DropdownInput
            :value="getValueAplicacao('idProd', 'idProdAplicacao', dimensaoCampanha)"
            label="PRODUTO"
            field="idProdAplicacao"
            :index="index"
            :disabled="!!dimensaoCampanha.aplicaregra"
            @input="updateField(index, 'idProdAplicacao', $event, dimensaoCampanha)"
        />
        <DropdownInput
            :value="getValueAplicacao('idProdPrincipal', 'idProdPrincipalAplicacao', dimensaoCampanha)"
            label="PRODUTO PRINCIPAL"
            field="idProdPrincipalAplicacao"
            :index="index"
            :disabled="!!dimensaoCampanha.aplicaregra"
            @input="updateField(index, 'idProdPrincipalAplicacao', $event, dimensaoCampanha)"
        />
      </div>

      <div v-if="campanha.validarPorRegra">
        <TipoValidacao
            :item="dimensaoCampanha"
            :inputShouldBeVisible="inputShouldBeVisible"
            :validacao="validacao"
            :disabled="campanha.erpId && campanha.id"
        />
      </div>
      <div class="button-delete-container">
        Aplicar o desconto de
        <input
            type="number"
            v-model="dimensaoCampanha.percDesc"
            :disabled="campanha.erpId && campanha.id"
        />
        % nos itens que compõem a regra de aplicação
        <button
            type="button"
            class="button button-error"
            v-on:click="excluirRegra(index)"
            :disabled="campanha.erpId && campanha.id"
        >
          <fa-icon icon="trash-alt" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import DropdownInput from '../DropdownInput.vue';
import TipoValidacao from "@/views/Campanhas/components/CampanhasComerciais/components/Regra/TipoValidacao.vue";

export default {
  components: { DropdownInput, TipoValidacao },
  props: ['campanha', 'dimensaoCampanha', 'validacao', 'tiposInputs', 'index'],
  methods: {
    excluirRegra(index) {
      this.campanha.dimensoes.splice(index, 1);
    },
    inputShouldBeVisible(inputName) {
      return (
          this.tiposInputs[this.campanha.validacao] &&
          this.tiposInputs[this.campanha.validacao].indexOf(inputName) >= 0
      );
    },
    clonarRegra(regraProduto) {
      if (regraProduto.aplicaregra) {
        regraProduto.idProdAplicacao = regraProduto.idProd;
        regraProduto.idProdPrincipalAplicacao = regraProduto.idProdPrincipal;
      }
    },
    updateField(index, field, value, regraProduto, mirrorField = null) {
      this.$set(this.campanha.dimensoes[index], field, value);

      if (regraProduto.aplicaregra && mirrorField) {
        this.$set(this.campanha.dimensoes[index], mirrorField, value)
      }
    },
    getValueAplicacao(validacaoField, aplicacaoField, regraProduto) {
      if (regraProduto.aplicaregra) {
        return regraProduto[validacaoField];
      }

      return regraProduto[aplicacaoField];
    }
  }
};
</script>

<style scoped>
.regra-produto-card {
  border: 2px solid #ddd;
  padding: 12px;
  margin-bottom: 24px;
}

.regra-produto-container {
  display: flex;
  position: relative;
  margin-bottom: 16px;
}

.regra-produto {
  position: relative;
  margin-right: 16px;
}

.regra-produto label {
  font-size: 12px;
  font-weight: bold;
}

.button-delete-container {
  overflow: hidden;
  display: block;
}

.button-delete-container input {
  width: 50px;
  text-align: right;
  border: 1px solid #999;
}

.button-delete-container button {
  float: right;
}

.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
  border: 1px solid #ddd;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}
</style>
