<template>
  <div class="regra-produto">
    <label>{{ label }}</label>
    <input
        type="text"
        v-model="inputValue"
        class="form-control"
        @input="fetchProdutos"
        @focus="showDropdown = true"
        @blur="hideDropdown"
        :disabled="disabled"
        placeholder="Código ou descrição do produto..."
    />

    <div v-if="showDropdown && produtos.length" class="dropdown-menu show" style="position: absolute;">
      <div
          v-for="produto in produtos"
          :key="produto.id"
          class="dropdown-item"
          @mousedown="selectProduto(produto)"
      >
        {{ produto.id + ' - ' + produto.descNF }}
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import { get } from "@/helpers/apiRequest";

export default {
  name: 'DropdownInput',
  props: {
    value: String,
    label: String,
    field: String,
    index: Number,
    disabled: Boolean
  },
  data() {
    return {
      produtos: [],
      showDropdown: false,
      inputValue: this.value,
      debouncedSearch: _debounce(this.loadProdutos, 500)
    };
  },
  methods: {
    fetchProdutos(event) {
      const term = event.target.value;
      this.debouncedSearch(term);
    },
    selectProduto(produto) {
      this.$emit('input', produto.id);
      this.showDropdown = false;
    },
    hideDropdown() {
      setTimeout(() => {
        this.showDropdown = false;
      }, 200);
    },
    loadProdutos(termo = '') {
      return get(`/admin/campanhas-produtos-paginados?termo=${termo}&take=48`)
          .then((json) => {
            this.produtos = json.itens;
          });
    }
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
    }
  }
};
</script>

<style scoped>
.dropdown-menu {
  max-height: 200px;
  overflow-y: auto;
  width: 100%;
  z-index: 1000;
  border: 1px solid #ddd;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f8f9fa;
}

.regra-produto {
  position: relative;
  margin-right: 16px;
}

.regra-produto label {
  font-size: 12px;
  font-weight: bold;
}
</style>
