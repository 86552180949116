<style scoped>
</style>

<template>
  <div class="mt-4">
    <div class="">
      <div>
        <div v-for="(dimensaoCampanha, index) in campanha.dimensoes" class="mt-2">
          <Segmento
              v-if="dimensaoCampanha.tipoRegra === 'SEGMENTACAO'"
              ref="segmento"
              :campanha="campanha"
              :dimensaoCampanha="dimensaoCampanha"
              :validacao="validacao"
              :dimensoes="dimensoes"
              :tiposInputs="tiposInputs"
              :index="index"
          />
          <Produto
              v-if="dimensaoCampanha.tipoRegra === 'PRODUTO'"
              :dimensaoCampanha="dimensaoCampanha"
              :campanha="campanha"
              :validacao="validacao"
              :tiposInputs="tiposInputs"
              :index="index"
          />
        </div>
        <div v-if="!campanha.erpId" class="col-1-1">
          <h5>Regra personalizada</h5>
          <select v-model="regraSelecionada" class="form-control w-25 col-2-1">
            <option value="SEGMENTACAO">Segmento</option>
            <option value="PRODUTO">Produto</option>
          </select>
          <button
              type="button"
              @click="adicionarRegra"
              class="button mt-2 mb-2 button-primary"
          >
            Adicionar Regra
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Segmento from "./Segmento.vue";
import Produto from "./Produto.vue";

const initialDimensao = () => ({
  dimensao1: "",
  dimensao2: "",
  dimensao3: "",
  dimensao4: "",
  dimensao5: "",
  dimensao6: "",
  dimensao7: "",
  dimensao8: "",
  dimensao9: "",
  dimensao10: "",
  dimensaoaplicacao1: "",
  dimensaoaplicacao2: "",
  dimensaoaplicacao3: "",
  dimensaoaplicacao4: "",
  dimensaoaplicacao5: "",
  dimensaoaplicacao6: "",
  dimensaoaplicacao7: "",
  dimensaoaplicacao8: "",
  dimensaoaplicacao9: "",
  dimensaoaplicacao10: "",
  percDesc: 0,
  aplicaregra: true,
  qtdeMin: 0,
  qtdeMax: null,
  qtdeMinDistinta: 0,
  qtdeMaxDistinta: null,
  vlMin: 0,
  vlMax: 0,
  percMin: 0,
  percMax: 0,
  tipoRegra: 'SEGMENTACAO'
});

const initialProduto = () => ({
  idProd: null,
  idProdAplicacao: null,
  idProdPrincipal: null,
  idProdPrincipalAplicacao: null,
  percDesc: 0,
  aplicaregra: true,
  qtdeMin: 0,
  qtdeMax: null,
  qtdeMinDistinta: 0,
  qtdeMaxDistinta: null,
  vlMin: 0,
  vlMax: 0,
  percMin: 0,
  percMax: 0,
  tipoRegra: 'PRODUTO'
});

  export default {
    components: {Segmento, Produto},
    props: ['campanha', 'validacao', 'dimensoes', 'tiposInputs'],
    data() {
      return {
        regraSelecionada: 'SEGMENTACAO',
        dimensao: initialDimensao(),
      }
    },
    methods: {
      adicionarRegra() {
        if (this.regraSelecionada === 'SEGMENTACAO') {
          this.campanha.dimensoes.push(initialDimensao());
        }
        if (this.regraSelecionada === 'PRODUTO') {
          this.campanha.dimensoes.push(initialProduto())
        }
      }
    }
  }
</script>
